import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import selfie from "../../Assets/Projects/selfie.jpg";
import youtemy from "../../Assets/Projects/youtemy.png";
import xai from "../../Assets/Projects/xai.png";
import llm from "../../Assets/Projects/llm.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={llm}
              isBlog={false}
              title="LLM"
              description="A LLM built using Python from scratch to explore Transformers and GPT."
              ghLink="https://github.com/RiteshK55/llm"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={xai}
              isBlog={false}
              title="XAI"
              description="A highly accurate (90.28%) deep neural network trained on the FRLL dataset for morphed image detection, leveraging EfficientNet
              architecture and facial feature identification"
              ghLink="https://github.com/RiteshK55/XAI"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={selfie}
              isBlog={false}
              title="Selfie Biometrics"
              description="Leveraged the ResNet architecture to propose a new CNN model that outperforms existing solutions by 87.25% in
              filter-applied face recognition, demonstrating significant accuracy gains.
              cases."
              ghLink="https://github.com/RiteshK55/SelfieBiometrics"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={youtemy}
              isBlog={false}
              title="YouTemy"
              description="Secured recognition as the Best Project in the Pre-Fellowship program. It's a user-friendly web app that empowers developers to efficiently learn from YouTube."
              ghLink="https://github.com/RiteshK55/youtemy"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
